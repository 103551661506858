import { createContext, useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';

const StoreContext = createContext({
  store: null,
  cookieStoreName: null,
  orderType: null,
  setStore: null,
  setStoreName: null,
  setOrderType: null,
  modal: null,
  setModal: null,
  zipcode: null,
  setZipcode: null,
  address: null, 
  setAddress: null,
});

export const StoreContextProvider = ({ children }) => {
  const cookieStore = Cookies.get("JANE_STORE");
  const cookieStoreName = Cookies.get("STORE_NAME");
  const cookieOrderType = Cookies.get("ORDER_TYPE");
  const cookieZipcode = Cookies.get("ZIP_CODE");

  const [store, setStore] = useState(cookieStore ? parseInt(cookieStore) : 4398);
  const [storeName, setStoreName] = useState(cookieStoreName || "San Jose");
  const [orderType, setOrderType] = useState(cookieOrderType || "Browsing");
  const [zipcode, setZipcode] = useState(cookieZipcode || '');
  const [modal, setModal] = useState(false);
  const [address, setAddress] = useState({});

  const initialRender = useRef(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const storedAddress = localStorage.getItem('userAddress');
      if (storedAddress) {
        setAddress(JSON.parse(storedAddress));
      }
    }
  }, []);

  // useEffect(() => {
  //   if (address) {
  //     console.log("Updated address: ", address);
  //   }
  // }, [address]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    Cookies.set("JANE_STORE", store, { expires: 365 });
    Cookies.set("STORE_NAME", storeName, { expires: 365 });
    Cookies.set("ORDER_TYPE", orderType, { expires: 365 });
    Cookies.set("ZIP_CODE", zipcode, { expires: 365 });

    if (address && typeof window !== 'undefined') {
      const addressString = JSON.stringify(address);
      localStorage.setItem('userAddress', addressString);
    }

  }, [store, storeName, orderType, zipcode, address]);

  const value = {
    store,
    setStore,
    storeName,
    setStoreName,
    orderType,
    setOrderType,
    modal,
    setModal,
    zipcode,
    setZipcode, 
    address,
    setAddress,
  };

  return (
    <StoreContext.Provider value={value}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContext;
