import Link from "next/link";
import { useRouter } from "next/router";

export default function RetainQueryLink({ href, ...props }) {
  const router = useRouter();
  const currentPath = router.pathname;
  const targetPath = typeof href === "object" ? href.pathname : href;

  // Destructure and exclude certain query parameters including 'page'
  const { category, subcategory, id, product, page, ...routerQuery } = router.query;

  // check if the path is /brands/
  const isBrandsPath = (path) => path.includes('/brands/');

  // Check if the current path is /brands/[brand]
  const isCurrentPageBrands = isBrandsPath(currentPath);

  // Check if the target path is /brands or /deals
  const shouldExcludeQueries = ['/brands', '/deals', '/rewards'].includes(targetPath) || isBrandsPath(targetPath);

  // If the current page is /brands/ page or the target path matches one of the specified paths, exclude all queries.
  // Otherwise, combine routerQuery with additional query parameters from href.
  const updatedQuery = isCurrentPageBrands || shouldExcludeQueries
    ? {}
    : {
        ...routerQuery,
        ...(typeof href === "object" && typeof href.query === "object" ? href.query : {}),
      };

  return (
    <Link
      {...props}
      href={{
        pathname: targetPath,
        query: updatedQuery,
      }}
    />
  );
};
