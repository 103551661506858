import { useContext } from "react";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Configure,
  useRefinementList,
  useClearRefinements,
} from "react-instantsearch-hooks-web";
import StoreContext from "../../context/store-context";
import Link from "next/link";
import RetainQueryLink from "../RetainQueryLink";

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_KEY
);
const indexName = process.env.NEXT_PUBLIC_ALGOLIA_INDEX;

export default function SubCategories(props) {
  const { store } = useContext(StoreContext);
  const { rootType } = props

  return (
    <InstantSearch indexName={indexName} searchClient={searchClient}>

      <Configure filters={`store_id:${store}`} />

      <CustomRefinementList
        attribute="root_types"
        rootType={rootType.toLowerCase()}
        limit={300}
      />

    </InstantSearch>
  );
}

function CustomRefinementList(props) {
  const { items, searchForItems, refine, isFromSearch } = useRefinementList(props);
  const { rootType } = props;
  // Filter items based on the rootType string
  const filteredItems = items.filter((item) => item.label.includes(rootType.toString().toLowerCase()) && item.label.includes(":") && !item.label.includes("$"));

  return (
    <>
      {filteredItems.length > 0 ? (
        <ul>
          <li>
            <RetainQueryLink
              href={`/shop/${encodeURIComponent(rootType.toLowerCase())}`}
            >
              All {rootType}
            </RetainQueryLink>
          </li>
          {filteredItems.map((item) => {
            // Remove first word and ":"
            const labelParts = item.label.split(":");
            const subcategory = labelParts.slice(1).join(":").trim();
            const category = labelParts[0].trim()
 
            return (
              <li key={item.label}>
                <Link
                  href={`/shop/${category}?subcategory=${item.label}`}
                >
                  {subcategory}
                </Link>
                {/* <p className="fs-300">{item.count} products available</p> */}
              </li>
            );
          })}
        </ul>
      ) : null}
    </>
  );
}