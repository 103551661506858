import styles from './Brand.module.scss'
import Link from 'next/link';

export default function BrandLink({ hit }) {
  const brand = hit.brand?.replace(/&/g, "%26");
  return (
    <Link
      href={`/brands/${brand?.replace(/ /g, "-")}`}
      aria-label={hit.brand || "brand"}
      attribute={hit.brand}
      onClick={e => e.stopPropagation()}
      prefetch={false}
      className={styles.brand}
    >
      {hit.brand}
    </Link>
  )
}