import { useEffect, useContext, useState } from "react";
import { useShoppingCart } from "../../context/shopping-cart-context";
import StoreContext from "../../context/store-context";
import styles from "./Bag.module.scss";
import { useRouter } from 'next/router';

export default function Cart() {
  const [frame, setFrame] = useState(null);
  const { cartItems, setCartItems, changeItemQuantity, removeFromBag, setCartModal, cartModal } = useShoppingCart();
  const { store, orderType, address } = useContext(StoreContext);

  const router = useRouter();

  // Watch for changes in the store
  useEffect(() => {
    if (store && frame && address && orderType) {
      const cartPayload = buildCartPayload(cartItems, store, address, orderType);
      if (frame.contentWindow) {
        frame.contentWindow.postMessage(cartPayload, '*');
      }
    }
  }, [store, frame, cartItems, address, orderType]);

  // Function to dynamically build the cart payload 
  const buildCartPayload = (cartItems, store, address, orderType) => {

    return {
      messageType: "buildCart",
      payload: {
        headlessCheckoutPartnerId: "86ec042e-b8d3-4cda-b605-70c2e123f3e2",
        products: cartItems,
        cognitoToken: undefined,
        storeId: store,
        reservationMode: orderType?.toLowerCase(),
        options: {
          theme: {
            themeColor: "#663D99",
            navigationColor: "red",
            ctaTextColor: "white",
            backgroundColor: "white",
            calloutBackground: "#379D47",
            calloutTextColor: "white",
            buttonBorderRadius: "24px",
          },
          redirectUrl: "http://plpcsanjose.com/shop",
          disableWeightSelection: true,
          disableLoadingSpinner: false,
          disableAuthFeatures: false,
          disablePrepayment: false,
          kiosk: false,
        },
        deliveryAddress: address ? {
          street: `${address.street_number} ${address.route}`,
          city: address.city,
          state_code: address.stateCode,
          zipcode: address.zip,
          lat: address.lat,
          long: address.lng,
        } : null,
      },
    };
  };

  const makeDrawerFullScreen = () => {
    const cartModal = document.querySelector("#cartModal");
    if (cartModal) {
      cartModal.classList.add("fullscreen");
    }
  };

  const minimizeDrawer = () => {
    const cartModal = document.querySelector("#cartModal");
    if (cartModal) {
      cartModal.classList.remove("fullscreen");
      cartModal.classList.add("minimized");
    }
  };

  // Minimize the drawer if navigating away from /checkout
  useEffect(() => {
    const handleRouteChange = (url) => {
      if (url !== '/checkout' && cartModal) {
        minimizeDrawer();
      }
    };

    // Subscribe to route change start event
    router.events.on('routeChangeStart', handleRouteChange);

    // Cleanup the event listener on component unmount
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [cartModal]);

  async function fetchCart(uuid) {
    try {
      const response = await fetch(`/api/jane-cart/${uuid}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching cart:', error);
    }
  }

  const receiveMessage = (event) => {
    const { messageType, payload } = event.data;
    const janeMenuFrame = document.getElementById('jane-menu');
    setFrame(janeMenuFrame);

    switch (payload?.name) {
      case "headlessAppLoaded":
        window.cartLoaded = true;
        const cartPayload = buildCartPayload(cartItems, store, address, orderType);
        janeMenuFrame.contentWindow.postMessage(cartPayload, '*');
        // if (router.pathname === '/checkout') {
        //   setCartModal(true);
        //   makeDrawerFullScreen();
        // }
        // else minimizeDrawer();
        break;
      case "drawerClosed":
        setCartModal(false);
        break;
      case "drawerContinueShopping":
        setCartModal(false);
        break;
      case "drawerProceedToCheckout":
        // router.push(`/checkout`);
        break;
      case "checkoutPageLoad":
        makeDrawerFullScreen();
        break;
      case "cartItemRemoval":
        const productId = payload.properties?.productId;
        removeFromBag(productId);
        if (cartItems.length === 0) {
          setCartModal(false);
        }
        break;
      case "cartItemClickedInCheckout":
        const clickedProductId = payload.properties?.productId;
        if (clickedProductId) {
          router.push(`/product/${clickedProductId}`);
        }
        setCartModal(false);
        break;
      case "cartItemChangeCount":
        changeItemQuantity(
          payload.properties.productId,
          payload.properties.priceId,
          payload.properties.count
        );
        break;
      case "checkout":
        fetchCart(payload.properties.uuid);
        router.push(`/receipt/${payload.properties.uuid}`);
        // Use the routeChangeComplete event to set cart items after the route has loaded
        const handleRouteChangeComplete = () => {
          setCartItems([]);
          router.events.off('routeChangeComplete', handleRouteChangeComplete);
        };
        // Attach the event listener
        router.events.on('routeChangeComplete', handleRouteChangeComplete);
        var subtotal = payload.properties && payload.properties.estimatedTotal;
        var cartId = payload.properties && payload.properties.cartId;

        // GTM          
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            transaction_id: cartId,
            value: subtotal,
            currency: "USD"
          }
        });
        break;
      default:
        break;
    }

    switch (messageType) {
      case 'loadingEvent':
        if (payload.name === 'headlessAppLoaded') {
          const cartPayload = buildCartPayload(cartItems, store, address, orderType);
          janeMenuFrame.contentWindow.postMessage(cartPayload, '*');
        }
        break;
      default:
        break;
    }
  };

  // Event listeners
  useEffect(() => {
    window.addEventListener('message', receiveMessage);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [frame, cartItems, store, address]);

  // Dynamically append script to script-container
  useEffect(() => {
    const scriptContainer = document.getElementById('script-container');

    if (scriptContainer) {
      const scriptElement = document.createElement('script');
      scriptElement.src = "https://api.iheartjane.com/v2/headless/embed.js";
      scriptElement.id = 'jane-frame-script';
      scriptElement.onload = () => {
        console.log('Jane embed script loaded');
      };

      // Clear any existing scripts before appending new one
      scriptContainer.innerHTML = '';
      scriptContainer.appendChild(scriptElement);
    }

    // Cleanup the script when the component unmounts
    return () => {
      if (scriptContainer) {
        scriptContainer.innerHTML = '';
      }
    };
  }, [store, address, orderType, cartItems]);

  return (
    <div className={styles.bag}>
      <div id="script-container"></div>
    </div>
  );
}
