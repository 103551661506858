export function convertTo12HourFormat(time) {
  if (!time) {
    return null;
  }
  
  const [hours, minutes] = time.split(":");
  const parsedHours = parseInt(hours, 10);
  const amPm = parsedHours >= 12 ? "PM" : "AM";
  const hour12 = parsedHours % 12 || 12; // Convert 0 to 12 for 12:00 AM
  const formattedMinutes = minutes === "00" ? "" : `:${minutes}`;
  return `${hour12}${formattedMinutes} ${amPm}`;
}

export function minutesToHoursMinutes(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  // Format the result as "hh:mm" or "h:mm" if the hour part is less than 10
  const formattedTime = `${hours}:${remainingMinutes < 10 ? '0' : ''}${remainingMinutes}`;

  return formattedTime;
}


export function deliveryTimeWithInterval(time1, time2) {
  // If time is null, default it to "00:00". This is to handle the case where time is null
  if (!time1) {
    return null
  }

  //assuming time 1 is HH:mm and time 2 is in mm... 
  // Split the time1 string into hours and minutes
  const [deliveryHours, deliveryMinutes] = time1.split(':').map(Number);

  // Calculate the total time in minutes for time1
  const totalMinutes1 = deliveryHours * 60 + deliveryMinutes;

  // Subtract time2 from time1
  const resultMinutes = totalMinutes1 - time2;

  if (resultMinutes < 0) {
    return 'Invalid subtraction';
  } else {
    // Calculate hours and minutes for the result
    const resultHours = Math.floor(resultMinutes / 60);
    const resultMinutesRemainder = resultMinutes % 60;

    // Format the result as 'hh:mm'
    return `${resultHours.toString().padStart(2, '0')}:${resultMinutesRemainder
      .toString()
      .padStart(2, '0')}`;
  }
}