// https://stackoverflow.com/questions/66129171/how-to-restrict-api-key-when-calling-the-reverse-geocoding-api-from-browser

// import { Loader } from '@googlemaps/js-api-loader';

// const loadScript = async () => {
//   const loader = new Loader({
//     apiKey: process.env.NEXT_PUBLIC_GOOGLE_API,
//     version: 'weekly',
//   });

//   await loader.load();
// };

export async function reverseGeo(lat, long) {
  // await loadScript();
  const geocoder = new google.maps.Geocoder();

  const latlng = {
    lat: lat,
    lng: long,
  };

  try {
    const { results } = await geocoder.geocode({ location: latlng });
    if (results && results[0]) {
      return results[0];
    }
  } catch (e) {
    // handle exception
  }

}