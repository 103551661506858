
import styles from './MobileMenu.module.scss'
import Toggle from '../../components/toggle'
import Link from 'next/link'
import SocialIcons from '../../components/social-icons/SocialIcons'
import Button from '../../components/Button/button'

import { faXmark, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navItems } from './NavItems'
import Image from 'next/image'

function Logo({ open }) {

	return (
		<Link href='/' aria-label="home" className={styles.logo} tabIndex={open ? 0 : -1}>
			<Image
				src="/purple-lotus-logo-black-text.svg"
				alt="mobile menu logo"
				width={160}
				height={45}
			/>
		</Link>
	)
}

export default function MobileMenu(props) {
	const handleClick = props.handleClick
	const open = props.open

	return (
		<div className={styles.mobileMenu}>
			<div className={styles.header}>
				{open && <Logo open={open} />}
				<Button type="button" className="icon xmark" ariaLabel="close mobile menu" onClick={() => handleClick()} tabIndex={open ? 0 : -1}>
					<FontAwesomeIcon icon={faXmark} />
				</Button>
			</div>
			<div className={styles.list}>

				{navItems.navItems.map((item) => {
					if (item.links) {
						return (
							<NavToggle
								key={item.title}
								title={item.title}
								link={item.link}
								handleClick={handleClick}
								links={item.links}
								open={open}
							/>
						);
					}
					else if (item.title !== 'home') {
						return (
							<NavItem
								key={item.title}
								title={item.title}
								link={item.link}
								handleClick={handleClick}
								links={item.links}
								open={open}
							/>
						);
					}
				})}

				<div className={styles.socials}>
					<SocialIcons tabIndex={open ? 0 : -1} />
				</div>

				<Link href="/old" className="link" style={{ marginTop: "auto", paddingTop: "2rem", paddingBottom: "2rem" }} tabIndex={open ? 0 : -1}>
					View old site <FontAwesomeIcon size="xs" icon={faChevronRight} />
				</Link>
			</div>
		</div>
	)

}

function NavItem(props) {
	const { link, title, handleClick, links, rightIcon, open } = props
	return (
		<Link
			href={link}
			className={styles.item}
			onClick={() => handleClick()}
			tabIndex={open ? 0 : -1}
		>
			{title}
			{rightIcon ? <FontAwesomeIcon className={styles.rightIcon} icon={rightIcon} /> : null}
		</Link>
	)
}

function NavToggle(props) {
	const { title, handleClick, links, open } = props
	return (
		<Toggle
			key={title}
			title={title}
			class={`${styles.toggle} ${title === 'about' || title === 'help' || title === 'info' ? styles.secondary : ''}`}
		>
			{links.map((item) => (
				<Link key={item.title} href={item.link} onClick={() => handleClick()} tabIndex={open ? 0 : -1}>
					{item.title}
				</Link>
			))}
		</Toggle>
	)
}