import styles from "./SocialIcons.module.scss";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

// todo : add and fetch from Sanity. pull className from title 

const socialLinks = [
  {
    title: "facebook",
    link: "https://www.facebook.com/plpc420",
    label: "Visit our Facebook page",
    icon: faFacebook,
  },
  {
    title: "instagram",
    link: "https://www.instagram.com/purplelotus.ca/",
    label: "Check out our Instagram",
    icon: faInstagram,
  },
  {
    title: "twitter",
    link: "https://x.com/purplelotusca",
    label: "Follow us on 𝕏",
    icon: faXTwitter,
  },
  {
    title: "youtube",
    link: "https://www.youtube.com/channel/UCjtoPKVTpNS-i4Zkqu9JkHQ",
    label: "Watch our videos on YouTube",
    icon: faYoutube,
  },
];

export default function SocialIcons({tabIndex}) {
  return (
    <ul className={styles.social_links} aria-label="social media links">
      {socialLinks.map((link) => (
        <SocialIcon key={link.label} {...link} tabIndex={tabIndex}/>
      ))}
    </ul>
  );
}

function SocialIcon({ title, link, label, icon, tabIndex }) {
  return (
    <li>
      <Link href={link} aria-label={label} className={styles[title]} tabIndex={tabIndex}>
        <FontAwesomeIcon icon={icon} />
      </Link>
    </li>
  );
}
