import { useEffect, useState } from 'react'
import Image from 'next/image'

export default function FallbackImage({ src, ...rest }) {
  const [imgSrc, setImgSrc] = useState(src)

  useEffect(() => {
    setImgSrc(src)
  },[src])

	return (
		<Image
      {...rest}
			src={imgSrc ? imgSrc : '/images/placeholder.png'}
			onError={() => {
        if (imgSrc !== '/images/placeholder.png') {
          setImgSrc('/images/placeholder.png');
        }
      }}
      
		/>
	);
}
