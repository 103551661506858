import { useEffect } from "react";

export default function useDisableBodyScroll(disable) {
  useEffect(() => {
    const body = document.querySelector('body');

    if (disable) {
      body.style.overflowY = 'hidden';

    } else {
      body.style.overflowY = 'scroll';
      body.style.position = '';

    }

  }, [disable]);

  return null;
}
