import Link from 'next/link';
import { useState } from 'react';
import styles from './Strain.module.scss';

export default function Strain(props) {
  const strain = props.category;
  const [showFullStrainName, setShowFullStrainName] = useState(false);

  let color;

  switch (strain) {
    case 'indica':
      color = 'indica';
      break;
    case 'indica-dominant':
      color = `indica-dominant`;
      break;
    case 'hybrid':
      color = `hybrid`;
      break;
    case 'sativa-dominant':
      color = `sativa-dominant`;
      break;
    case 'sativa':
      color = `sativa`;
      break;
    case 'cbd':
      color = `cbd`;
      break;
    default:
      color = null;
      break;
  }

  return (
    <>
      {strain ? (
        <Link
          href={'/shop?strain=' + `${strain}`}
          className={`${styles.strain} ${color}`}
          onClick={(e) => e.stopPropagation()}
          style={strain === 'cbd' ? { textTransform: 'uppercase' } : null}
          // onMouseEnter={() => setShowFullStrainName(true)}
          // onMouseLeave={() => setShowFullStrainName(false)}
        >
          {/* {showFullStrainName ? strain : strain.charAt(0)} */}
          {strain}
        </Link>
      ) : null}
    </>
  );
}
