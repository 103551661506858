import { useEffect, useCallback } from "react";

export default function useOnClickOutside(ref, handler, excludedSelector) {
  const memoizedHandler = useCallback(
    (event) => {
      // Check if the clicked element matches the excluded selector
      if (excludedSelector && event.target.matches(excludedSelector)) {
        return;
      }

      // Check if the clicked element or its parents have the .pac-container class (for google autocomplete dropdown)
      let element = event.target;
      while (element) {
        if (element.classList.contains('pac-container')) {
          return;
        }
        element = element.parentElement;
      }

      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    },
    [handler, excludedSelector]
  );

  useEffect(() => {
    const listener = (event) => {
      memoizedHandler(event);
    };

    document.addEventListener("click", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("click", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, memoizedHandler]);
}