import { useEffect, useCallback } from "react";
import { useLottie } from "lottie-react";

export function LottieIcon({ animatedIcon, isHovered }) {
  const options = {
    animationData: animatedIcon,
    loop: false,
    autoplay: false, // Disable autoplay initially
  };

  const { View, playSegments, stop } = useLottie(options);

  // Create a function to play the animation from a specific startFrame to endFrame
  const playAnimation = useCallback(
    (startFrame, endFrame) => {
      playSegments([startFrame, endFrame], true);
    },
    [playSegments]
  );

  useEffect(() => {
    if (isHovered) {
      playAnimation(0, animatedIcon.op); // Replace "op" with the actual end frame of your animation
    } else return
  }, [isHovered, playAnimation, stop]);

  return View;
}