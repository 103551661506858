// modal for location.js

// this component should be rewritten to pull every store, then make a pickup option available if pickup is available (instead of hard coding the options, see: pickupLocation()). Also requires cleanup

import { useContext, useMemo, useState, useEffect } from "react";
import {
  faXmark,
  faArrowRight,
  faChevronDown,
  faChevronUp,
  faLocationDot,
  faPhone,
  faInfoCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { faInfoCircle as faInfoCircle2 } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StoreContext from "../../context/store-context";
import Link from "next/link";
import styles from "./Modal.module.scss";
import GooglePlaces from "./GooglePlaces";
import { useRouter } from "next/router";
import SkeletonElement from "../skeletons/SkeletonElement";
import { LottieIcon } from "../Lottie/icon";
import { useShoppingCart } from "../../context/shopping-cart-context";
import { convertTo12HourFormat } from "../../utils/formatTime";
import deliveryTruck from "../../public/icons/animated/car.json";
import shoppingBags from "../../public/icons/animated/shopping-bags.json";
import { toast } from "react-toastify";
import Button from "../Button/button";
import Image from "next/image";

export default function LocationModal({ modal, setModal }) {
  const [tab, setTab] = useState();
  const { orderType } = useContext(StoreContext);
  const [hasRenderedModal, setHasRenderedModal] = useState(false);

  useMemo(() => {
    if (orderType == "Delivery") setTab(1);
    else if (orderType == "Pickup") setTab(2);
    else return;
  }, [orderType]);

  useEffect(() => {
    if (modal && !hasRenderedModal) {
      setHasRenderedModal(true);
    }
  }, [modal, hasRenderedModal]);

  return (
    <div className={modal ? styles.popupOpen : styles.popupClosed}>
      <Backdrop setModal={setModal} />
      {hasRenderedModal &&
        <div className={styles.popup}>
          <div className={styles.popup__inner}>
            <div className={styles.popup__header}>
              <span className={styles.popup__title}>
                How do you want to shop?
              </span>
              <Button
                className="icon xmark"
                type="button"
                ariaLabel="close location selector"
                onClick={() => setModal(false)}
              >
                <FontAwesomeIcon icon={faXmark} />
                {/* <span>close</span> */}
              </Button>
            </div>

            <div className={styles.tabs}>
              <Tab
                id={1}
                name="Delivery"
                tab={tab}
                setTab={setTab}
                icon={deliveryTruck}
              />
              <Tab
                id={2}
                name="Pickup"
                tab={tab}
                setTab={setTab}
                icon={shoppingBags}
              />
            </div>

            <div
              className={
                tab === 1
                  ? `${styles.tab__content} ${styles.active__content}`
                  : `${styles.tab__content}`
              }
            >
              <DeliveryOptions setModal={setModal} modal={modal} />
            </div>

            <div
              className={
                tab === 2
                  ? `${styles.tab__content} ${styles.active__content}`
                  : `${styles.tab__content}`
              }
            >
              <PickupLocations setModal={setModal} />
            </div>
          </div>
        </div>
      }
    </div>

  );
}

function Tab({ id, name, setTab, tab, icon }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    setTab(id);
    setIsHovered(true);
    setTimeout(() => setIsHovered(false), 100);
  };

  return (
    <button
      className={
        tab === id
          ? `${styles.tab} ${styles.active} lottie-dark-hover`
          : `${styles.tab} ${isHovered ? "lottie-dark-hover" : "lottie-dark"}`
      }
      onClick={() => handleClick()}
    >
      <LottieIcon animatedIcon={icon} isHovered={isHovered} />
      <span>{name}</span>
    </button>
  );
}

// todo: should be mapped data from jane, so any store with pickup enabled should be displayed
function PickupLocations({ setModal }) {
  return (
    <div className={styles.pickup_locations}>
      {/* <PickupLocation store_id={117} setModal={setModal} virtualTour /> */}
      <PickupLocation store_id={4398} setModal={setModal} virtualTour />
      <PickupLocation store_id={5981} setModal={setModal} virtualTour />
      {/* <PickupLocation store_id={2100} setModal={setModal} /> */}
    </div>
  );
}

function PickupLocation({ store_id, setModal, virtualTour, inactive }) {
  const { store, setStore, setOrderType, setStoreName, orderType, setZipcode } =
    useContext(StoreContext);
  const { setCartItems } = useShoppingCart();
  const router = useRouter();

  const [storeData, setStoreData] = useState();
  const [dayOfWeek, setDayOfWeek] = useState(getDayOfWeek());
  const [showAllHours, setShowAllHours] = useState(false);
  const slug = storeData?.url_slug
    .replace("purple-lotus-san-jose-", "")
    .replace(/-pickup$/, "");
  const [isImageHovered, setIsImageHovered] = useState(false);

  useEffect(() => {
    async function fetchStoreData() {
      try {
        const response = await fetch(`/api/jane-store/${store_id}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setStoreData(data?.data.store);
      } catch (error) {
        console.error('Failed to fetch store data:', error);
      }
    }

    fetchStoreData();
  }, []);

  function getDayOfWeek() {
    const today = new Date();
    return new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(today);
  }

  const toggleHoursVisibility = (e) => {
    e.preventDefault();
    setShowAllHours((prev) => !prev);
  };

  const isSelectedStore = () =>
    store === storeData.id && orderType === "Pickup";

  const handleStoreSelect = () => {
    setModal(false);
    setStore(storeData.id);
    setOrderType("Pickup");
    console.log(storeData);
    setStoreName(storeData.address);
    setZipcode(storeData.zip);
    router.replace(router.asPath);
    // empty cart if store changes
    if (store !== storeData.id) {
      setCartItems([]);
    }
    toast(
      <div className="pr-200">
        <span className="fw-600">Store changed to Pickup:</span><br />{storeName}
      </div>,
      {
        position: "bottom-center",
        autoClose: 1500,
        hideProgressBar: false,
        pauseOnHover: true,
        draggable: true,
        className: "location-toast",
      }
    );
  };

  const storeName = storeData?.name
    .replace("Purple Lotus - ", "") // Remove "Purple Lotus - "
    .replace(/ Pickup$/, ""); // Remove " Pickup" at the end, including the space
  

  return (
    <div className={styles.pickup_location}>
      {storeData ? (
        <>
          <div className={styles.store}>

            <div className={styles.storeHeader}>

              {storeData.primary_gallery_image &&
                <Link href={`/pickup/${slug}`} className={styles.image} onClick={() => setModal(false)} onMouseEnter={() => setIsImageHovered(true)}
                  onMouseLeave={() => setIsImageHovered(false)}>
                  <Image
                    src={storeData.primary_gallery_image}
                    alt={storeData.name}
                    fill
                    style={{ objectFit: "cover" }}

                  />
                  {isImageHovered && (
                    <FontAwesomeIcon icon={faInfoCircle2} />
                  )}
                </Link>
              }

              <div>
                {storeData.id === 5981 ? <div className={styles.new}>Downtown Location</div> : null}
                <div className={styles.storeTitle}>
                  {storeName}
                </div>
                <div className={styles.location}>

                  <FontAwesomeIcon icon={faLocationDot} size="xs" className="color-primary-300" />
                  <Link href={`/pickup/${slug}#map`} className="address" onClick={() => setModal(false)}>
                    <div className="fs-300">{storeData.address}</div>
                    <div className="fs-300">{storeData.city}, CA</div>
                  </Link>
                </div>
              </div>

            </div>

            <div className={styles.storeInfo}>

              <div>
                <a href={`tel:${storeData.phone.replace(/\D/g, "")}`} className="link fs-300">
                  <FontAwesomeIcon icon={faPhone} size="xs" style={{ paddingRight: '3px' }} />
                  {storeData.phone}
                </a>

                <Link
                  href={`/pickup/${slug}`}
                  className="link fs-300"
                  onClick={() => setModal(false)}
                >
                  <FontAwesomeIcon icon={faInfoCircle} style={{ paddingRight: '3px' }} />More Info
                </Link>

                {/* <Link
                  href={`/pickup/${slug}#parking`}
                  className="link fs-300"
                  onClick={() => setModal(false)}
                >
                  <FontAwesomeIcon icon={faParkingCircle} style={{ paddingRight: '3px' }} />Parking
                </Link> */}

                {/* {virtualTour && (
                  <Link
                    href={`/pickup/${slug}#tour`}
                    className="link fs-300"
                    onClick={() => setModal(false)}
                  >
                    <FontAwesomeIcon icon={faStreetView} style={{ paddingRight: '3px' }} />Virtual Tour
                  </Link>
                )} */}
              </div>

              <div className="hours">
                {storeData.pickup_hours.map((i, index) => {
                  const isToday =
                    i.day.toLowerCase() === dayOfWeek.toLowerCase();
                  if (showAllHours || isToday) {
                    return (
                      <div
                        key={index}
                        style={{ fontSize: "0.875rem" }}
                        className={`day ${isToday && showAllHours ? "today" : ""
                          }`}
                      >
                        <span style={{ fontSize: "0.875rem" }}>
                          {i.day.substring(0, 3)}
                        </span>
                        <div>
                          {convertTo12HourFormat(i.period.from)} -{" "}
                          {convertTo12HourFormat(i.period.to)}
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
                {showAllHours ? <div className={styles.openEveryday}>
                  Open everyday,<br /> including holidays.
                </div> : null}
                <div
                  className="link"
                  style={{ textAlign: "right", fontSize: "0.875rem" }}
                  role="button"
                  onClick={toggleHoursVisibility}
                >
                  {showAllHours ? "View Less" : "View all Hours"}
                  <FontAwesomeIcon
                    className="fa-sm color-neutral-600"
                    style={{ paddingLeft: "5px" }}
                    icon={showAllHours ? faChevronUp : faChevronDown}
                  />
                </div>
              </div>

            </div>

          </div>
          {inactive ?
            <Button type="button" className="button inactive">Closed</Button>
            :
            <button
              className={`button w-100 ${isSelectedStore() ? styles.selected : ""}`}
              data-type="primary"
              onClick={handleStoreSelect}
            >
              {isSelectedStore() ? "Selected" : "Select Store"}
            </button>
          }
        </>
      ) : (
        <div className={styles.skeleton}>
          <div>
            <SkeletonElement type="thumbnail" />
            <div>
              <SkeletonElement type="title" />
              <SkeletonElement type="text" />
              <SkeletonElement type="text" />
            </div>
          </div>
          <div>
            <SkeletonElement type="text" />
            <SkeletonElement type="text" />
            <SkeletonElement type="spacer-small" />
            <SkeletonElement type="button-large" />
          </div>

        </div>
      )}
    </div>
  );
}

function DeliveryOptions({ setModal, modal }) {
  return (
    <div className={styles.delivery_locations}>
      <div className={styles.delivery_input}>
        <GooglePlaces setModal={setModal} modal={modal} />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Link
          href="/weed-delivery#map"
          onClick={() => {
            setModal(false);
          }}
        >
          <span className="fw-semi-bold color-neutral-600 fs-300 link">
            View delivery map{" "}
          </span>
          <FontAwesomeIcon
            className="fw-bold color-neutral-600 fs-100"
            icon={faArrowRight}
          />
        </Link>
      </div>
    </div>
  );
}

function Backdrop({ setModal }) {
  return (
    <div className={styles.backdrop} onClick={() => setModal(false)}></div>
  );
}
