import React, { useEffect, useRef, useState, useContext } from "react";
import styles from "./GooglePlacesMinimal.module.scss";
import {
  faLocationCrosshairs,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StoreContext from "../../context/store-context";

import { reverseGeo } from "./reverseGeo";
import { Loader } from "@googlemaps/js-api-loader";

const fetcher = (...args) => fetch(...args).then((res) => res.json());

const apiKey = process.env.NEXT_PUBLIC_GOOGLE_API;

const extractAddress = (place) => {
  const address = {
    city: "",
    state: "",
    zip: "",
    country: "",
    lat: "",
    lng: "",
    street: "",
    plain() {
      const street_number = this.street_number ? this.street_number + " " : "";
      const route = this.route ? this.route + ", " : "";
      const city = this.city ? this.city + ", " : "";
      const state = this.state ? this.state + ", " : "";
      const zip = this.zip ? this.zip : "";
      return street_number + route + city + state + zip;
    },
    street() {
      const street_number = this.street_number ? this.street_number + " " : "";
      const route = this.route ? this.route : "";
      return street_number + route;
    },
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = component.types;
    const value = component.long_name;

    if (types.includes("locality")) {
      address.city = value;
    }

    if (types.includes("administrative_area_level_1")) {
      address.state = value;
      address.stateCode = component.short_name;
    }

    if (types.includes("postal_code")) {
      address.zip = value;
    }

    if (types.includes("country")) {
      address.country = value;
    }

    if (types.includes("street_number")) {
      address.street_number = value;
    }

    if (types.includes("route")) {
      address.route = value;
    }
  });

  if (place.geometry && place.geometry.location) {
    address.lat = place.geometry.location.lat();
    address.lng = place.geometry.location.lng();
  }

  return address;
};

export default function GooglePlacesMinimal({ deliveryStores, deliveryZips, setSelectedDeliveryStore,  setSelectedDeliveryAddress = () => {}  }) {
  const searchInput = useRef(null);

  const {
    store,
    setStore,
    setStoreName,
    address,
    setAddress,
  } = useContext(StoreContext);
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState(false);

  const clearInput = (e) => {
    e.preventDefault();
    if (searchInput.current) {
      searchInput.current.value = ""; // Clear the input field value
    }
    setAddress(null);
    setSelectedDeliveryAddress(null);
    setSelectedDeliveryStore(null);
    setSuccessMessage(false);
    setErrorMessage(false);
  };

  // init gmap script. todo: move to utility file so a single instance can be run for multiple components
  const initMapScript = () => {
    // Create a new Loader instance
    const loader = new Loader({
      apiKey: apiKey,
      version: "weekly",
      libraries: ["places"],
      async: true, 
      defer: true,
    });

    return loader.load();
  };

  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    setAddress(extractAddress(place));
    submitAddress(extractAddress(place));
  };

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  // init map script and autocomplete
  useEffect(() => {
    initMapScript().then(() => {
      initAutocomplete();
      console.log("autocomplete loaded");
    });
  }, []);

  // get geocoordinates, then reverse geocode to get address
  const findMyLocation = () => {
    setAddress(null); // clear the previous address
    setSuccessMessage(false);

    if (navigator.geolocation) {
      searchInput.current.value = "Finding your location...";
      navigator.geolocation.getCurrentPosition(
        (position) => {
          reverseGeo(position.coords.latitude, position.coords.longitude)
            .then((address) => {
              const _address = extractAddress(address);
              setAddress(_address);
              submitAddress(_address);
              searchInput.current.value = _address.plain();
            })
            .catch((error) => {
              console.log(error);
              setErrorMessage(
                "Could not fetch your location. Please try again."
              );
            });
        },
        (error) => {
          console.error("error: " + error);
          setErrorMessage("Could not fetch your location. Please try again.");
        }
      );
    } else {
      setErrorMessage("Your browser does not support geolocation.");
    }
  };

  // check if the address is in the delivery area, then set the store
  function submitAddress(address) {
    if (address?.zip && deliveryZips.length > 0) {
      const zipcode = deliveryZips.map((zip) => zip.zipcode);
      if (zipcode.includes(address.zip)) {
        const selectedZip = deliveryZips.find((zip) => zip.zipcode === address.zip);
        const selectedStore = deliveryStores?.find(
          (store) => store.id === selectedZip.store_id
        );
        setStore(selectedStore.id);
        setSelectedDeliveryStore(selectedStore);
        setErrorMessage(false);
        setSuccessMessage(true);
      } else if (!zipcode.includes(address.zip)) {
        // setSelectedDeliveryStore(null);  // Reset the delivery store
        // setSelectedDeliveryAddress(null); // Reset the delivery address
        setSuccessMessage(false);
        setErrorMessage("Delivery is not available to that location");
        setAddress(null);
        setSelectedDeliveryAddress(null);
        setSelectedDeliveryStore(null);
      }
    } else {
      setSuccessMessage(false);
      setErrorMessage("Please select an address from the dropdown");
    }
  }

  return (
    <div className={`${styles.location}`}>
      <>
        <div>
          <div className={styles.findMyLocation} onClick={findMyLocation}>
            <FontAwesomeIcon icon={faLocationCrosshairs} />
            Use My Current Location
          </div>

          <div className={styles.search}>
            <div className={styles.inputContainer}>
              <input
                ref={searchInput}
                id="address"
                type="text"
                placeholder="Enter your delivery address"
              />
              <FontAwesomeIcon
                icon={faXmark}
                className={styles.clearIcon}
                onClick={clearInput}
              />
            </div>
          </div>

          {successMessage && <SuccessMessage address={address} />}

          <span className={styles.errorMessage}>
            {errorMessage && errorMessage}
          </span>
        </div>
      </>
    </div>
  );
}

function SuccessMessage({ address }) {
  const addressString =
    address.street_number +
    " " +
    address.route +
    ", " +
    address.city

  return (
    <div className={styles.successMessage}>
      Success! We deliver to {addressString}
    </div>
  );
}
