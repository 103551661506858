import { useEffect, useState, useContext, useMemo } from "react";
import styles from "./AgeGateLocation.module.scss";
import Link from "next/link";
import StoreContext from '../../context/store-context';
import { LottieIcon } from "../../components/Lottie/icon";
import GooglePlacesMinimal from "../../components/location/GooglePlacesMinimal";
import Button from "../../components/Button/button";
import { useShoppingCart } from "../../context/shopping-cart-context";
import deliveryTruck from "../../public/icons/animated/car.json";
import shoppingBags from "../../public/icons/animated/shopping-bags.json";
import { convertTo12HourFormat } from "../../utils/formatTime";
import SkeletonElement from "../../components/skeletons/SkeletonElement";

export default function AgeGate({ router, ageGate, setAgeGate }) {
  const { modal, setModal, setOrderType, setStore, setStoreName, setZipcode, address, orderType } = useContext(StoreContext);
  const { setCartItems } = useShoppingCart();
  const [tab, setTab] = useState(2);
  const [selectedPickupStore, setSelectedPickupStore] = useState(null);
  const [selectedDeliveryStore, setSelectedDeliveryStore] = useState(null);
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(null);
  const [warning, setWarning] = useState(false);
  const [pickupStores, setPickupStores] = useState([]);
  const [deliveryStores, setDeliveryStores] = useState([]);
  const [deliveryZips, setDeliveryZips] = useState([]);

  // useEffect(() => {
  //   console.log(selectedPickupStore)
  // }, [selectedPickupStore])

  useEffect(() => {
    const ageGateValue = localStorage.getItem("ageGate");
    const body = document.querySelector("body");
    if (router.asPath === "/terms-of-use" || router.asPath === "/privacy-policy") {
      setAgeGate(false);
      body.style.overflowY = "scroll";
    } else if (ageGateValue) {
      setAgeGate(false);
      body.style.overflowY = "scroll";
    } else {
      setAgeGate(true);
      body.style.overflowY = "hidden";
    }
  }, [ageGate, router]);

  const closeAgeGate = () => {
    if ((tab === 1 && selectedDeliveryStore) || (tab === 2 && selectedPickupStore)) {
      if (tab === 1 && selectedDeliveryStore) {
        // Handle delivery case
        setStore(selectedDeliveryStore.id);
        setStoreName(address.plain());
        setZipcode(selectedDeliveryStore.zip);
        setOrderType("Delivery");
      } else if (tab === 2 && selectedPickupStore) {
        // Handle pickup case
        setStore(selectedPickupStore.id);
        setStoreName(selectedPickupStore.full_address);
        setZipcode(selectedPickupStore.zip);
        setOrderType("Pickup");
        // Empty the cart if the store changes
        if (selectedPickupStore.id !== selectedPickupStore) {
          setCartItems([]);
        }
      }
      setAgeGate(false);
      localStorage.setItem("ageGate", true);
      setModal(false); // Close modal after confirming age
    } else if (tab === 2) {
      // Only display warning if no valid selection on the Pickup tab
      setWarning(true);
    }
  };

  useEffect(() => {
    fetchStores();
  }, []);

  const fetchStores = async () => {
    try {
      const response = await fetch('/api/jane-stores');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json = await response.json();
      const stores = json?.data.stores;
      // Filter out the Purple Lotus store 117 and sandbox 2100
      const storesWithPickup = stores?.filter(
        (store) => store.pickup === true && store.id !== 117 && store.id !== 2100
      );
      const storesWithDelivery = stores?.filter(
        (store) => store.delivery === true
      );
      setPickupStores(storesWithPickup);
      setDeliveryStores(storesWithDelivery);

      console.log('Pickup stores:', storesWithPickup);
      console.log('Delivery stores:', storesWithDelivery);

      // Call fetchDeliveryZips after delivery stores are set
      if (storesWithDelivery) {
        fetchDeliveryZips(storesWithDelivery);
      }
    } catch (error) {
      console.error('Failed to fetch pickup stores:', error);
    }
  }

  const fetchDeliveryZips = async (deliveryStores) => {
    // Make a request for each store id to get the store data
    const zipPromises = deliveryStores.map(async (store) => {
      try {
        const response = await fetch(`/api/jane-store/${store.id}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const res = await response.json();
        const data = res.data.store;

        // Return the zip codes for this store
        return data.delivery_zipcodes;
      } catch (error) {
        console.error("Failed to fetch store data:", error);
        return [];
      }
    });

    // Resolve all promises and merge the zip codes into a single array
    const zipsArray = await Promise.all(zipPromises);
    const mergedZips = zipsArray.flat(); // Flatten the array of arrays

    // Set the zip codes array
    setDeliveryZips(mergedZips);
    console.log("Delivery store zips:", mergedZips);
  };


  return (
    ageGate && (
      <div className={styles.ageGate} id="ageGate">
        <div className={styles.content}>

          <div className={styles.orderTypeSelection}>
            <div className={styles.tabs}>
              <Tab
                id={2}
                name="Pickup"
                tab={tab}
                setTab={setTab}
                icon={shoppingBags}
                setWarning={setWarning}
              />
              <Tab
                id={1}
                name="Delivery"
                tab={tab}
                setTab={setTab}
                icon={deliveryTruck}
                setWarning={setWarning}
              />
            </div>

            <div className={styles.tabContent}>
              {!tab ? null : tab === 1 ? (
                <div className={styles.deliveryTab}>
                  <GooglePlacesMinimal
                    selectedAddress={selectedDeliveryAddress}
                    setSelectedDeliveryAddress={setSelectedDeliveryAddress}
                    setSelectedDeliveryStore={setSelectedDeliveryStore}
                    deliveryStores={deliveryStores}
                    deliveryZips={deliveryZips}
                  />
                </div>
              ) : tab === 2 ? (
                <div className={styles.pickupTab}>
                  <div className={styles.pickupLocations}>
                    {pickupStores.length > 0 ? (
                      pickupStores.map((store, index) => (
                        <PickupLocation
                          key={index}
                          store={store}
                          selectedStore={selectedPickupStore}
                          setSelectedStore={setSelectedPickupStore}
                        />
                      ))
                    ) : (
                      <>
                      <PickupLocationSkeleton />
                      <PickupLocationSkeleton />
                      </>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {warning && (
            <div className={styles.warningText}>
              Please select a shop
            </div>
          )}

          <div className="fw-bold fs-200 text-center pt-2">
            You must be 21+ or 18+ with a medical card
          </div>

          <div className={styles.buttons}>
            <Button
              type="button"
              className={`button openLocationButton primary ${(tab === 1 && !selectedDeliveryStore) || (tab === 2 && !selectedPickupStore)
                ? "disabled"
                : ""
                }`}
              data-type="primary"
              style={{ borderRadius: "50px" }}
              onClick={closeAgeGate}
              disabled={
                (tab === 1 && !selectedDeliveryAddress) ||
                (tab === 2 && !selectedPickupStore)
              }
            >
              I'm old enough
            </Button>

            <Button
              type="link"
              href="about:blank"
              className="link underline"
              data-type="primary"
              style={{ fontSize: "0.875rem" }}
            >
              I'm too young
            </Button>
          </div>

          <div>
            <div className="fs-200 color-neutral-650 text-center">
              By entering this site I agree to the{" "}
              <Link href="/terms-of-use" className="link" style={{ textDecoration: "underline" }}>
                Terms of Service
              </Link>{" "}
              &amp;{" "}
              <Link href="/privacy-policy" className="link" style={{ textDecoration: "underline" }}>
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  );

  // Tab function for Order Type selection
  function Tab({ id, name, setTab, tab, icon, setWarning }) {
    const [isHovered, setIsHovered] = useState(false);

    const handleClick = () => {
      setTab(id);
      setIsHovered(true);
      setTimeout(() => setIsHovered(false), 100);
      setWarning(false);
    };

    return (
      <button
        className={
          tab === id
            ? `${styles.tab} ${styles.active} lottie-dark-hover`
            : `${styles.tab} ${isHovered ? "lottie-dark-hover" : "lottie-dark"}`
        }
        onClick={() => handleClick()}
      >
        <LottieIcon animatedIcon={icon} isHovered={isHovered} />
        <span>{name}</span>
      </button>
    );
  }

  function PickupLocation({ store, selectedStore, setSelectedStore }) {

    const handleStoreSelect = () => {
      setSelectedStore(store); // Pass the entire store object when selected
      setWarning(false);
    };

    const storeName = store?.name.includes('(')
      ? store.name.match(/\(([^)]+)\)/)[1] // Extract content inside parentheses
      : store?.name.replace("Purple Lotus - ", ""); // Remove "Purple Lotus - " if no parentheses

    return (
      <div
        className={`${styles.store} ${selectedStore?.id === store.id ? styles.selected : styles.unselected}`} // Use selectedStore?.id to compare
        onClick={handleStoreSelect}
      >
        <div className={styles.storeLeft}>
          <div className={styles.storeTitle}>
            {storeName}
          </div>
          <div className="hours">
            {store.hours.pickup_hours.map((i, index) => {
              const today = new Date();
              const currentDay = new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(today);

              const isToday = i.day.toLowerCase() === currentDay.toLowerCase();
              if (isToday) {
                return (
                  <div key={index} className={styles.hours}>
                    <div>{convertTo12HourFormat(i.period.from)} - {convertTo12HourFormat(i.period.to)}</div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>

        <div className={styles.storeRight}>
          <div className={styles.location}>
            <div className="fs-300">
              {store.full_address?.replace(/\bCalifornia\b/, "CA")}
            </div>
          </div>
        </div>
      </div>
    );
  }

}

function PickupLocationSkeleton() {
  return (
    <div className={styles.store}>
      <div className={styles.skeleton}>
        <div className={styles.skeletonLeft}>
          <SkeletonElement type="title" />
          <SkeletonElement type="text" />
        </div>
        <div className={styles.skeletonRight}>
          <SkeletonElement type="text" />
          <SkeletonElement type="text" />
        </div>
      </div>
    </div>
  )
}
