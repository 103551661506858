// the location navitem/mobile bar
import { useState, useContext, useMemo, useRef, useEffect } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import StoreContext from '../../context/store-context'
import Modal from './Modal'

import { faChevronDown, faChevronUp, faLocationDot as faLocationDotRegular } from '@fortawesome/pro-regular-svg-icons';
import { faLocationDot as faLocationDotSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Location.module.scss'
import useOnClickOutside from "../../hooks/useOnClickOutside";
import SkeletonElement from '../skeletons/SkeletonElement';
import useDisableBodyScroll from "../../hooks/useDisableBodyScroll";
import Button from '../Button/button.js';

export default function Location() {

  const {
    modal,
    setModal,
    storeName,
    orderType,
  } = useContext(StoreContext);
  const [hasMounted, setHasMounted] = useState(false);
  useDisableBodyScroll(modal)

  // close on click outside
  const ref = useRef()
  useOnClickOutside(ref, () => setModal(false), ".openLocationButton");

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null; 
  }
  return (
    
    <div ref={ref} className={styles.container}>
  

      <Button
        type="button"
        className="icon location"
        onClick={() => setModal(!modal)}
        ariaLabel={`${orderType + " " + storeName}`}
      // onKeyDown={() => setModal(true)}
      // tabIndex="0"
      >
        <div className={styles.location__content}>
          <FontAwesomeIcon icon={faLocationDotSolid} />

          <div className={styles.location__text}>
            <span className={styles.orderType}>{orderType}</span>
            {" "}
            <span className={styles.storeName}>{storeName}</span>
          </div>

          {!modal ? (<FontAwesomeIcon icon={faChevronDown} />) : (<FontAwesomeIcon icon={faChevronUp} />)}
        </div>

      </Button>

      <Modal modal={modal} setModal={setModal} />
    </div>

  );
}